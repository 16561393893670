@font-face {
  font-family: roboto;
  src: url(./fonts/roboto/Roboto-Regular.ttf);
}


a, a:hover, a:focus, a:active {
     text-decoration: none;
     color: inherit;
}

h1{
  font-size: calc(20px + 2vmin);

}

body{
  margin: 0;
  padding: 0;
}

p{
  margin: 0px;
}

h2{
  padding-top: 10px;
  padding-bottom: 10px;
}

section{
  padding-bottom: 1em;
  width: 100%;
}

.App {
  text-align: center;
  background-color: white;
  color: #00415A;
  font-family: roboto;
  width: 100%;
}

.icon{
  flex: 1;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.intro-header{
  font-size: calc(40px + 2vmin);
  text-align: left;
  margin-top: 2em;
  margin-left: 50px;
  font-weight: 700;
}

.about-me-summary{
  width: 40em;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

.education-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.education-summary{
  width: 22em;
}

.project-container{
  display: inline-flex;
  flex-direction: row;
}

.project-panel{
  margin-top: 2vh;
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  flex-direction: row;
  border-radius: 20px;
  max-width: 20em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.small-title{
  font-size: calc(8px + 2vmin);
  text-decoration: underline;
}

.project-title{
  align-items: center;
}

.project-pic{
  width: auto;
  min-height: 215px;
}

.project-summary{
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
  font-size: calc(3px + 2vmin);
  align-self: center;
}

.nav-container{
  display: flex;
  min-height: 10vh;
  white-space: nowrap;
  align-items: center;

}

.nav-list{
  display: flex;
  margin-left: 20em;
  font-size: calc(10px + 2vmin);

}

.nav-item{
  display: flex;
  padding: 2vh 2vw 2vh 2vw;
}

.contact-div{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.contact-icon{
  margin-left: 2vw;
  margin-right: 2vw;
  max-width: auto;
  height: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-main-div {
  font-size: calc(10px + 2vmin);
}

.github-div{
  margin-top: 10vh;
  align-items: center;
  display: inherit;
}

.github-img{
  width: 15vw;
  margin-left: 2vw;
}

.resume-header {
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: row;
  margin-left: 300px;
  margin-right: 300px;
}

.aboutme-summary{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.experience{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
}

.tech-div{
  display: flex;
  flex-direction: column;
  width: 10em;
  height: 8em;
  margin-left: 1em;
  margin-right: 1em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 10px;
  padding: 1em 1em 1em 1em;
}

.hobby-div{
  width: 10em;
  margin-left: 1em;
  margin-right: 1em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 10px;
  padding: 1em 1em 1em 1em;
}

.skills-list{
  text-align: center;
  padding: 0;
  align-items: center;
}

.profile-container{
  align-items: center;
}

.footer{
   #TODO: add github link
}

.github-link {
  color: #61dafb;
}

.profile-pic{
  max-width: 15em;
  flex: 1;
}

.summary-div {
  margin-left: 20px;
  margin-right: 2em;
  margin-top: 0px;
  margin-bottom: 40px;
  min-width: 400px;

}

.experience-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.experience{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.experience-panel{
  display: flex;
  flex-direction: column;
  width: 12em;
  height: 10em;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  padding: 1em 1em 1em 1em;
}


.logo-img {
  max-width: 5em;
  flex: 1;
  margin: 2vh 2vw 2vh 2vw;
}

.title{
  font-size: 4vh;
  flex: 1;
}

.youtube-desc{
  padding-top: 40px;
}


.Summary-div {
  border-style: 'groove';
  padding: 40;
}

#contact{
  background-color: #00415A;
  color: white;
  padding-top: 30px;

}

#projects{
  background-color: #00415A;
  color: white;
  padding-top: 30px;
}

#experience{
  background-color: white;
  color: #00415A;
  padding-top: 30px;
}

#education{
  background-color: white;
  color: #00415A;
  padding-top: 30px;  
}

#hobbies{
  background-color: #00415A;
  color: white;
  padding-top: 30px;  
  padding-bottom: 50px;  
}

#aboutme{
  background-color: #00415A;
  color: white;
  padding-top: 30px;
  align-content: center;
  justify-content: center;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
